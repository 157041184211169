import React from 'react';
import { Text } from 'native-base';

export const Example = () => {
  return (
    <Text color="white" fontSize="5xl">
      Tt
    </Text>
  );
};
